import './lib/polyfills';
import lazySizes from './lib/lazysizes';
import ViewportHeight from "./modules/ViewportHeight";
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import 'gsap';
import Reveal from './modules/Reveal';
require('intersection-observer');

/*
*   Bootstrap app
*
 */
const init = () => {
    Viewport.init();
    Viewport.releaseTabbing();
    ViewportHeight.init();
    Components.init();
    lazySizes.init();
    Reveal.init();
};

require('doc-ready')(init);
