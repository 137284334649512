import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

export default (el, props) => {
    const OFFSET_BUFFER = 5;
    const OFFSET_TOP = 30;
    const OFFSET_TRANSPARENCY = 150;
    let growAmount = 47;
    let needsTransparency = $('body').hasClass('has-transparent-bar');
    let isTransparent = needsTransparency;
    const dom = $(el);
    const menuToggle = dom.find('[data-menu-toggle]');
    const content = dom.find('[data-header-content]');
    const bar = dom.find('[data-header-bar]');
    let headerShowing = true;
    let isSmall = false;


    let lastTop = Viewport.scroll.top;


    const toggleSize = (shouldBeBig, isMenuToggle = false) => {

        if (shouldBeBig && isSmall) {
            isSmall = false;
            dom.removeClass('is-small');
            TweenMax.to(content.get(0), 0.35, { delay: isMenuToggle ? 0.4 : 0, y: 0, ease: 'Quad.easeOut' });
            TweenMax.to(bar.get(0), 0.35, { delay: isMenuToggle ? 0.65 : 0, y: 0, ease: 'Quad.easeOut' });

        } else if (!shouldBeBig && !isSmall) {
            isSmall = true;
            dom.addClass('is-small');
            if (isMenuToggle) {
                TweenMax.to(content.get(0), 0.5, { y: -growAmount });
            } else {
                TweenMax.set(content.get(0), { y: -growAmount });
            }
            TweenMax.set(bar.get(0), { y: -growAmount });
        }
    };

    const show = () => {
        if (!headerShowing) {
            console.log('Header.show');
            headerShowing = true;
            TweenMax.to(content.get(0), 0.35, { yPercent: 0, ease: 'Quad.easeOut' });
            TweenMax.to(bar.get(0), 0.35, { yPercent: 0, ease: 'Quad.easeOut' });
        }
    };

    const hide = () => {
        if (headerShowing) {
            console.log('Header.hide');
            headerShowing = false;
            TweenMax.to(content.get(0), 0.25, { yPercent: -100, ease: 'Quad.easeIn' });
            TweenMax.to(bar.get(0), 0.25, { yPercent: -100, ease: 'Quad.easeIn' });
            isSmall = lastTop > OFFSET_TOP;
        }
    };
    const onMenuToggle = () => {
        dom.toggleClass('has-open-menu');
        
        if (dom.hasClass('has-open-menu')) {
    
            toggleSize(true, true);
            Viewport.off('scroll', onScroll);
        } else if (!dom.hasClass('has-open-menu')) {
   
            toggleSize(Viewport.scroll.top < OFFSET_TRANSPARENCY, true);
            Viewport.on('scroll', onScroll);
        }
    };

    const onToggleClick = e => {
        e.preventDefault();
        Dispatch.emit(Events.MENU_TOGGLE);
    };

    const toggleTransparency = shouldToggle => {
        if (needsTransparency && shouldToggle) {
            isTransparent = !isTransparent;
            dom.toggleClass('is-opaque');
        }
    };

    const onScroll = () => {
        const top = Viewport.scroll.top;
        if (Math.abs(top - lastTop) > OFFSET_BUFFER) {
            const isScrollingUp = top < lastTop;
            if (isScrollingUp) {
                show();
                toggleTransparency(!isTransparent && top < OFFSET_TRANSPARENCY);
                toggleSize(top < OFFSET_TRANSPARENCY);
            } else if (top > OFFSET_TOP) {
                toggleTransparency(isTransparent && top >= OFFSET_TRANSPARENCY);
                hide();
            }
            lastTop = top;
        }
    };

    const onBreakpoint = () => {
        if (Viewport.width < 750) {
            growAmount = 27;

        } else {
            growAmount = 47;

        }
        if (isSmall) {
            isSmall = false;
            toggleSize(false);
        }
    };

    const init = () => {
        Viewport.on('scroll', onScroll);
        Viewport.on('breakpoint', onBreakpoint);
        if (Viewport.width < 750) {
            growAmount = 27;

        }

        if (!$('body').hasClass('has-transparent-bar') && $('[data-hero-image]').length > 0) {
            $('body').addClass('has-transparent-bar');
            needsTransparency = true;
            isTransparent = true;
        } else if ($('body').hasClass('has-transparent-bar') && $('[data-hero-image]').length < 1) {
            $('body').removeClass('has-transparent-bar');
            needsTransparency = false;
            isTransparent = false;
        }

        Dispatch.on(Events.MENU_TOGGLE, onMenuToggle);
        menuToggle.on('click', onToggleClick);

        toggleSize(Viewport.scroll.top < OFFSET_TOP);
    };

    const destroy = () => {
        
        dom.removeClass('has-open-menu');

        Viewport.off('scroll', onScroll);
        menuToggle.off('click', onMenuToggle);
    };

    return {
        init,
        destroy
    };
};