import $ from '@vaersaagod/tools/Dom';

import 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

const scroll = ScrollToPlugin;

export default (el, props) => {
    const dom = $(el);

    let target = 0;
    let delta = 0;
    let tickId = 0;

    const padNumber = value => (value > 9 ? value : `0${value}`);

    const getDiffParts = ms => {
        let s = Math.round(ms / 1000);
        let m = Math.floor(s / 60);
        s %= 60;
        let h = Math.floor(m / 60);
        m %= 60;
        const d = Math.floor(h / 24);
        h %= 24;

        return { h: padNumber(h), m: padNumber(m), s: padNumber(s) };
    };

    const getDiff = () => Math.max(0, target - (Date.now() + delta));

    const tick = () => {
        const diff = getDiff();
        const parts = getDiffParts(diff);
        dom.text(`${parts.h}:${parts.m}:${parts.s}`);
        if (diff === 0) {
            clearInterval(tickId);
            dom.addClass('heartbeat');
            TweenLite.to(window, 1, { scrollTo: { y: 0, autoKill: false }, ease: Cubic.easeInOut, onComplete: () => {
                setTimeout(() => {
                    if (document.location.href.indexOf('mode=countdown')) {
                        document.location.href = document.location.href.replace('mode=countdown', 'mode=active');
                    } else {
                        document.location.reload();
                    }
                }, 2000);
            }});
        }
    };

    const init = () => {
        target = Date.parse(props.target);
        delta = Date.parse(props.now) - Date.now();
        tickId = setInterval(tick, 1000);
        tick();
    };

    const destroy = () => {
        clearInterval(tickId);
    };

    return {
        init,
        destroy
    };
};
