import $ from '@vaersaagod/tools/Dom';
const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default (el, props) => {
    const dom = $(el);
    const groups = dom.find('[data-group]');
    const years = dom.find('[data-year]');
    let flkty;

    const updateGroup = year => {
        console.log(year);
        groups.removeClass('d-n');
        groups.addClass('d-n');
        groups.filter(g => $(g).data('group') === year).removeClass('d-n');
        window.history.replaceState({}, '', `${document.location.pathname}?y=${year}`);
    };

    const updateYear = node => {
        years.removeClass('is-active');
        $(node).addClass('is-active');
    };

    const onClick = e => {
        e.preventDefault();

        const node = e.triggerTarget;

        updateGroup($(node).data('year'));
        updateYear(node);
    };

    const init = () => {
        if (!document.location.search) {
            const lastYear = years.eq(0);
            window.history.replaceState({}, '', `${document.location.href}?y=${lastYear.data('year')}`);
            updateGroup(lastYear.data('year'));
            updateYear(lastYear.get(0));
        }

        loadFlickity(Flickity => {
            flkty = new Flickity(dom.find('[data-slider]').get(0), {
                cellSelector: '[data-slide]',
                wrapAround: false,
                contain: true,
                pageDots: false,
                freeScroll: true,
                prevNextButtons: false,
                draggable: true,
                dragThreshold: 1,
                cellAlign: 'left',
                setGallerySize: true,
                percentPosition: true,
                accessibility: true,
            });
        });

        years.on('click', onClick);
    };

    const destroy = () => {
        years.off('click', onClick);
    };

    return {
        init,
        destroy
    };
};
