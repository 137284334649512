import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

import 'gsap';

const TweenMax = window.TweenMax;
const TimelineMax = window.TimelineMax;

let revealItems = $('[data-reveal]');
let observer;

const observeEl = el => {
    const $el = $(el);
    const reveal = $el.data('reveal') || {};
    const { type } = reveal;
    if (type === 'zoom') {
        const { scale } = reveal;
        TweenMax.set(el, { opacity: 0.00001, scale: scale || 1.02, yPercent: 1, transformOrigin: 'center bottom' });
    } else if (type === 'fade') {
        TweenMax.set(el, { opacity: 0.00001 });
    } else {
        TweenMax.set(el, { opacity: 0.00001, y: reveal.y || 30 });
    }
    observer.observe(el);
    if (type) {
        $el.addClass(`js-reveal--${type}`);
    }
};

const onObserve = entries => {
    const items = {};
    entries.forEach(entry => {
        const { isIntersecting, target } = entry;

        if (isIntersecting) {
            const { type } = $(target).data('reveal') || {};
            if (type === 'zoom') {
                items.zoom = (items.zoom || []).concat([target]);
            } else {
                items.vertical = (items.vertical || []).concat([target]);
            }
            observer.unobserve(target);
        }
    });
    Object.keys(items).forEach(key => {
        const els = items[key];
        TweenMax.set(els, { 'will-change': 'opacity, transform' });
        if (key === 'zoom') {

            const animate = el => {
                new TimelineMax()
                    .to(el, 0.5, { opacity: 1, clearProps: 'opacity' }, 0)
                    .to(el, 1, { scale: 1, yPercent: 0, ease: 'Quad.easeOut', transformOrigin: 'center bottom', clearProps: 'opacity, y, scale, transform-origin, will-change' }, 0);
            };

            els.forEach(el => {
                // Defer animation for containers with lazy loaded images
                const $el = $(el);
                let lazyImages = $el.find('img.lazyload:not(.lazyloaded)').get();
                if ($el.hasClass('.lazyload') && !$el.hasClass('lazyloaded')) {
                    lazyImages.push(el);
                }
                lazyImages = lazyImages.filter(img => !!img);
                if (lazyImages.length) {
                    lazyImages.forEach(lazyImage => {
                        const handler = () => {
                            animate(el);
                            lazyImage.removeEventListener('lazyloaded', handler);
                        };
                        lazyImage.addEventListener('lazyloaded', handler);
                    });
                } else {
                    animate(el);
                }
            });

        } else if (key === 'fade') {
            new TimelineMax()
                .staggerTo(els, 0.5, { opacity: 1, clearProps: 'opacity' }, 0.05, 0);
        } else if (key === 'vertical') {
            new TimelineMax()
                .staggerTo(els, 0.3, { opacity: 1, clearProps: 'opacity' }, 0.05, 0)
                .staggerTo(els, 0.45, { opacity: 1, y: 0, ease: 'Cubic.easeOut', clearProps: 'y, will-change' }, 0.05, 0);
        }
    });
};

const onAjax = () => {
    revealItems = $('[data-reveal]');
    revealItems.each(el => {
        observeEl(el);
        el.removeAttribute('data-reveal');
    });
};

const init = () => {
    Dispatch.on(Events.AJAX_LOAD, onAjax);
    observer = new IntersectionObserver(onObserve, {
        root: null,
        //rootMargin: `0px 0px -${Math.floor(Viewport.height/10)}px 0px`,
        rootMargin: `0px 0px -40px 0px`,
        threshold: 0
    });

    revealItems.each(el => {
        observeEl(el);
        $(el).attr('data-reveal', null);
    });
};

export default {
    init
};
