import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const body = $('body');
    const toggles = dom.find('[data-toggle]');
    const container = dom.find('[data-items]');


    const onToggle = e => {
        e.preventDefault();

        const node = e.triggerTarget;
        const data = $(node).data('toggle');
        console.log(node.href);

        if (data.type === 'status') {
            body.toggleClass('investments--exited');
            body.toggleClass('investments--current');
            let query = '';
            if (body.hasClass('investments--exited')) {
                query = '?exited=true';
            }
            window.history.replaceState({url: `${document.location.pathname}${query}`}, '', `${document.location.pathname}${query}`);
        }

        if (data.type === 'fund') {
            toggles.filter(t => $(t).hasClass('is-active')).removeClass('is-active');
            $(node).addClass('is-active');
            const url = data.value;
            let query = document.location.search;
            request.get(url).query(query).then(res => {
                console.log('loadItems: SUCCESS');
                const {text} = res || {};
                container.html(text);
                const missingCurrent = container.find('[data-exited="0"]').length === 0;
                query = missingCurrent ? '?exited=true' : '';
                body.toggleClass('investments--exited', missingCurrent);
                body.toggleClass('investments--current', !missingCurrent);
                window.history.replaceState({url: `${url}${query}`}, '', `${url}${query}`);
                Dispatch.emit(Events.AJAX_LOAD);
            }, error => {
                console.error('loadItems: ', error);
            });
        }
    };

    const onNav = e => {
        e.preventDefault();
        window.sessionStorage.setItem('previous', `${document.location.href}`);
        document.location = e.triggerTarget.href;
    };

    const init = () => {
        const missingCurrent = container.find('[data-exited="0"]').length === 0;
        if (missingCurrent && document.location.search.indexOf('exited=true') === -1) {
            const url = document.location.href.replace(document.location.search, '') + '?exited=true';
            body.addClass('investments--exited');
            body.removeClass('investments--current');
            window.history.replaceState({url: url}, '', url);
        }else {
            window.history.replaceState({url: document.location.href}, '', `${document.location.href}`);
        }
        toggles.on('click', onToggle);
        dom.on('click', '[data-investment]', onNav);
    };

    const destroy = () => {
        dom.off('click', onNav);
        toggles.off('click', onToggle);
    };

    return {
        init,
        destroy
    };
};
