export default el => {
    const init = () => {
        const previous = window.sessionStorage.getItem('previous');
        window.sessionStorage.removeItem('previous');

        if (previous) {
            el.href = previous;
        }
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};