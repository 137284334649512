import $ from '@vaersaagod/tools/Dom';
import 'gsap';

const TimelineMax = window.TimelineMax;

export default el => {
    const dom = $(el);
    const images = dom.find('[data-image]');

    const duration = 0.4;
    const stayDelay = 0.9;
    const easeIn = 'Sine.easeOut';
    const easeOut = 'Sine.easeIn';

    const init = () => {
        const timeline = new TimelineMax({repeat:-1});

        images.get().forEach((node, index) => {
            const isOdd = index%2 !== 0;
            const startScale = isOdd ? 1.1 : 1;
            const endScale = isOdd ? 1 : 1.1;
            const inDelay = 0.2;

            const imageTimeline = new TimelineMax();

            imageTimeline
                .set(node, {opacity: 0, scale: startScale})
                .to(node, duration, { delay: inDelay, opacity: 1, scale: endScale, ease: easeIn })
                .to(node, duration, { delay: stayDelay, opacity: 0, scale: startScale, ease: easeOut});
            timeline.add(imageTimeline, timeline.duration() - (isOdd && index !== 0 ? 0.5 : 0));
        });
    };

    const destroy = () => {};

    return {
        init,
        destroy
    };
};
