import Viewport from '@vaersaagod/tools/Viewport';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

import 'gsap';

const TweenMax = window.TweenMax;
const TimelineMax = window.TimelineMax;

export default el => {
    const dom = $(el);
    const panel = dom.find('[data-panel]');
    const panelInner = dom.find('[data-panel-inner]');
    // const imageWrap = dom.find('[data-menu-image-wrap]');
    // const image = dom.find('[data-menu-image]');
    const cols = dom.find('[data-items-col]');
    const menuPoints = dom.find('[data-menu-point]');
    const searchInput = dom.find('#search');

    let timeline;

    const keyup = e => {
        const key = e.keyCode || e.which;

        if (key === 27) {
            Dispatch.emit(Events.MENU_TOGGLE);
        }
    };

    const afterClose = () => {
        TweenMax.set(el, { clearProps: 'all' });
        if (timeline) {
            timeline.kill();
            timeline = null;
            afterClose();
        }
    };

    const open = () => {

        document.addEventListener('keyup', keyup);

        console.log({ timeline });

        if (timeline) {
            timeline.play();
            return;
        }

        // dom.removeClass('d-n');
        timeline = new TimelineMax()
            .set(el, { display: 'block' }, 0)
            .set(panel.get(0), { yPercent: -100 }, 0)
            // .set(imageWrap.get(0), { yPercent: -100 }, 0)
            // .set(image.get(0), { yPercent: 95}, 0)
            .to(panel.get(0), 0.85, { yPercent: 0, ease: 'Quint.easeInOut', clearProps: 'all' }, 0)
            // .to(imageWrap.get(0), 0.85, { yPercent: 0, ease: 'Quint.easeInOut', clearProps: 'all' }, 0.2)
            // .to(image.get(0), 0.85, { yPercent: 0, ease: 'Quint.easeInOut', clearProps: 'all' }, 0.2)
            
            .staggerFrom(menuPoints.nodes, 0.4, { clearProps: 'all', opacity: 0, y: 20, ease: 'Quad.easeOut' }, 0.04, 0.6 )

            .to(el, 0.3, { opacity: 1 }, 0);

        if (Viewport.width >= 1200) {
            timeline
                .staggerFrom(cols.nodes, 0.6, { clearProps: 'all', y: 20, ease: 'Quad.easeOut' }, 0.2, 0.6 );
        }
            
            // .staggerFrom(menuPoints.nodes, 0.6, { clearProps: 'all', opacity: 0, x: 20, ease: 'Quad.easeOut' }, 0.1, 0.2 );
    };

    const close = () => {

        document.removeEventListener('keyup', keyup);

        TweenMax.to(el, 0.3, { opacity: 0, onComplete: afterClose });

        // timeline = new TimelineMax()
        //     .set(el, { clearProps: 'all' })
    };

    const onToggle = () => {
        const isOpen = dom.data('open');

        if (isOpen) {
            close();
        } else {
            open();
        }

        dom.data('open', !isOpen);
    };

    const onInputChange = () => {
        if (searchInput.val().length) {
            searchInput.addClass('has-input');
        } else {
            searchInput.removeClass('has-input');
        }
    };

    const setInnerPanelHeight = () => {
        panelInner.css('height', `${window.innerHeight}px`);
    };

    const onWindowResize = () => {
        setInnerPanelHeight();
    };

    const init = () => {

        dom.data('open', false);
        Dispatch.on(Events.MENU_TOGGLE, onToggle);
        searchInput.on('change paste keyup', onInputChange);

        // image.find('img').addClass('lazypreload');

        const active = menuPoints.find('a').filter(m => m.href === document.location.href.replace(document.location.search, ''));
        $(active).parent().addClass('is-active');

        Viewport.on('resize', onWindowResize);
        onWindowResize();
    };

    const destroy = () => {
        dom.data('open', false);
        Dispatch.off(Events.MENU_TOGGLE, onToggle);
        afterClose();
    };

    return {
        init,
        destroy
    };
};
